.category-card .item-image-cover{
    width: 100%;
    padding-top: 100%; 
    border-radius: 8px;
    background-color: black;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}
.category-card .item-image-cover>div{
    position:  absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}